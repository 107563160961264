<template>
  <span class="arrow-icon" :class="{ open: active }" :style="vars">
    <span class="left-bar" />
    <span class="right-bar" />
  </span>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    active: { type: Boolean, default: false },
    color: { type: String, default: "#6b7177" },
    size: { type: [String, Number], default: "20" },
    thickness: { type: [String, Number], default: "3" },
  },
  computed: {
    vars() {
      return {
        "--icon-color": this.color,
        "--icon-size": convertToUnit(this.size),
        "--icon-thickness": convertToUnit(this.thickness),
      };
    },
  },
});
</script>

<style lang="scss" scoped>
$easing: cubic-bezier(0.25, 1.7, 0.35, 0.8);
$duration: 0.5s;

.arrow-icon {
  display: block;
  position: relative;
  cursor: pointer;
  height: var(--icon-size);
  width: var(--icon-size);
  top: calc(var(--icon-size) / 2);
  right: calc(var(--icon-size) / 2);
}

.left-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  width: var(--icon-size);
  height: calc(var(--icon-size) / 4);
  display: block;
  transform: rotate(35deg);
  float: right;
  border-radius: 2px;
  &:after {
    content: "";
    background-color: var(--icon-color);
    width: var(--icon-size);
    height: var(--icon-thickness);
    display: block;
    float: right;
    border-radius: 3px calc(var(--icon-size) / 4) calc(var(--icon-size) / 4) 3px;
    transition: all $duration $easing;
    z-index: -1;
  }
}

.right-bar {
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: calc(var(--icon-size) / 2 + var(--icon-thickness));
  width: var(--icon-size);
  height: calc(var(--icon-size) / 4);
  display: block;
  transform: rotate(-35deg);
  float: right;
  border-radius: 2px;
  &:after {
    content: "";
    background-color: var(--icon-color);
    width: var(--icon-size);
    height: var(--icon-thickness);
    display: block;
    float: right;
    border-radius: calc(var(--icon-size) / 4) 3px 3px calc(var(--icon-size) / 4);
    transition: all $duration $easing;
    z-index: -1;
  }
}

.arrow-icon.open {
  .left-bar:after {
    transform-origin: center center;
    transform: rotate(-70deg);
  }
  .right-bar:after {
    transform-origin: center center;
    transform: rotate(70deg);
  }
}
</style>
